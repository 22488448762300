import React, { useState, useEffect } from 'react'
import { useContent } from '../../contexts/ContentContext';
import Btn from '../shared/Btn';
import Bumper from '../shared/Bumper';
import LabeledComponent from '../shared/LabeledComponent';
import TextEditor from '../shared/TextEditor';
import Loading from '../shared/Loading';
import Alert from '../shared/Alert';

function EditWork() {
    const { workSections, projectList, updateWorkSection } = useContent();
    const [sectionId, setSectionId] = useState("");
    const [section, setSection] = useState(null);

    const [blurb, setBlurb] = useState("");
    const [projects, setProjects] = useState([]);
    const [order, setOrder] = useState("");
    const [path, setPath] = useState("");
    const [title, setTitle] = useState("");

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reverter, setReverter] = useState(true);

    const SPACE_BETWEEN_TEXT_FIELD = 10;

    const revert = () => {
        setReverter(!reverter);
    }

    useEffect(() => {
        setSection(workSections.find(s => s.id === sectionId))
    }, [sectionId, workSections])

    useEffect(() => {
        const projs = () => {
            if (!section?.projects) {
                return [];
            }
            return section.projects
                .map(p => projectList.find(q => q.id === p) || null)
                .filter(p => p !== null);
        }
        setTitle(section?.title || "");
        setPath(section?.path || "");
        setBlurb(section?.blurb || "");
        setOrder(section?.order || "");
        setProjects(projs());
    }, [section, reverter, projectList])

    const checkOrder = (str) => {
        if (str === "") {
            setOrder("");
            return;
        }
        const reg = new RegExp('^[0-9]+$');
        if (!reg.test(str)) {
            return;
        }
        setOrder(parseInt(str));
    }

    const newProject = () => {
        setProjects((prev) => [...prev, ""])
    }

    const setProjectAtIndex = (index, projId) => {
        const projs = [...projects];
        projs[index] = projectList.find(p => p.id === projId)
        setProjects(projs.filter(p => p));
    }

    const saveSection = async () => {
        const updated = updatedWorkSection();
        if (!updated) {
            return;
        }
        setLoading(true);
        setError(null);
        const res = await updateWorkSection(sectionId, updatedWorkSection());
        if (res.error) {
            console.log("got error updating work section: ", res)
            setError({
                headline: "Error",
                body: "Could not update work section.",
                isError: true,
            });
        } else {
            setError({
                headline: "Victory",
                body: "Work section was updated, and the peasants rejoiced.",
                isError: false,
            })
        }
        setLoading(false);
    }

    const updatedWorkSection = () => {
        if (!title || !path || !blurb || !order) {
            setError({
                headline: "All Fields Required",
                body: "Something is missing.",
                isError: true,
            })
            return null;
        }
        return {
            blurb,
            order,
            // filter out empty strings/null values
            projects: projects.map(p => p.id),
            path,
            title,
        }
    }

    const projectsEditor = () => {
        return (
            <LabeledComponent label="Projects">
                <div
                    className="col"
                    style={{
                        paddingLeft: "20px",
                        borderLeft: "1px solid var(--very-light-grey)",
                        marginTop: "10px",
                    }}
                >
                    {projects.map((p, i) => {
                        return (
                            <form key={i}>
                                <select
                                    id="projects"
                                    name="projects"
                                    defaultValue={p.id}
                                    onChange={(e) =>
                                        setProjectAtIndex(i, e.target.value)
                                    }
                                >
                                    <option value="" key={-1}>[REMOVE]</option>
                                    {projectList.sort((a, b) => a.title > b.title ? 1 : -1).map((p, i) => {
                                        return (
                                            <option
                                                value={p.id}
                                                key={i}
                                            >
                                                {p.title}
                                            </option>
                                        )
                                    })}
                                </select>
                                <Bumper size={SPACE_BETWEEN_TEXT_FIELD} />
                            </form>
                        )
                    })}
                    <Bumper size={SPACE_BETWEEN_TEXT_FIELD} />
                    <button
                        onClick={newProject}
                    >
                        New
                    </button>
                </div>
            </LabeledComponent>
        )
    }

    return (
        <div className="col">
            <form>
                <select
                    id="sections"
                    name="sections"
                    onChange={(e) => setSectionId(e.target.value)}
                    style={{
                        marginTop: "60px"
                    }}
                >
                    <option value="" key={-1}>Select Section...</option>
                    {workSections.map((s, i) => {
                        return (
                            <option value={s.id} key={i}>{s.title}</option>
                        )
                    })}
                </select>
            </form>
            {error &&
                <Alert
                    headline={error.headline || "Error"}
                    body={error.body || "Undefined error."}
                    isError={error.isError}
                />
            }
            {sectionId &&
                <div
                    className="col"
                    style={{
                        maxWidth: "var(--max-nav-width)"
                    }}
                >
                    <TextEditor
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        label="Title"
                    />
                    <Bumper size={SPACE_BETWEEN_TEXT_FIELD} />
                    <TextEditor
                        value={path}
                        onChange={(e) => setPath(e.target.value)}
                        label="Path"
                    />
                    <Bumper size={SPACE_BETWEEN_TEXT_FIELD} />
                    <TextEditor
                        value={blurb}
                        onChange={(e) => setBlurb(e.target.value)}
                        label="Blurb"
                        minRows={10}
                        maxRows={15}
                    />
                    <Bumper size={SPACE_BETWEEN_TEXT_FIELD} />
                    <TextEditor
                        value={order}
                        onChange={(e) => checkOrder(e.target.value)}
                        label="Menu Order"
                    />
                    <Bumper size={SPACE_BETWEEN_TEXT_FIELD} />
                    {projectsEditor()}
                    <Bumper size={SPACE_BETWEEN_TEXT_FIELD * 4} line={true} />
                    {loading
                        ? <Loading />
                        : <div className="row">
                            <Btn
                                onClick={revert}
                                text="Revert"
                            />
                            <Btn
                                onClick={saveSection}
                                disabled={!title
                                    || !path
                                    || !blurb
                                    || (!order && order !== 0)
                                }
                                text="Save"
                            />
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default EditWork

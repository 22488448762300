import React from 'react'
import { useAuth } from '../../contexts/AuthContext'
import Admin from './Admin';
import SignIn from './SignIn';

function AdminWrap() {

    const { currentUser } = useAuth();

    return (
        currentUser ? <Admin /> : <SignIn />
    )
}

export default AdminWrap

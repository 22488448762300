import React from 'react'
import PageWrap from '../shared/PageWrap'
import Blurb from '../shared/Blurb'
import Bumper from '../shared/Bumper'
import { useContent } from '../../contexts/ContentContext'

function Contact() {
    const { text } = useContent();
    const emailStyle = {
        padding: "50px",
        fontWeight: "500",
        fontSize: "30px",
    }

    return (
        <PageWrap title="Contact">
            <Bumper size={30} />
            {text?.contactBlurb &&
                <Blurb text={text.contactBlurb} />
            }
            <div
                className="col"
                style={emailStyle}
            >
                <a
                    href="mailto:info@ingratexr.com"
                    style={{ textDecoration: "none" }}
                >
                    info@ingratexr.com
                </a>
            </div>
        </PageWrap>
    )
}

export default Contact

import React from 'react'

function Btn({ text, onClick, disabled }) {

    const style = {
        backgroundColor: disabled ? "var(--very-light-grey)" : "var(--red)",
        padding: "20px",
        margin: "20px",
        fontFamily: "var(--bold-font)",
        color: "white",
        fontSize: "40px",
        border: "none",
        cursor: disabled ? "" : "pointer",
    }

    const _onClick = () => {
        if (disabled) {
            return;
        }
        onClick();
    }

    return (
        <button style={style} onClick={_onClick}>
            {text}
        </button>
    )
}

export default Btn

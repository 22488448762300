// All the magic constants that make the world go round

// Max screen widths, in pixels, for different screen types:
export const breakpoints = {
    tabletLarge: 1100,
    tablet: 850,
    phone: 650,
    phoneSmall: 350,
}

// Responsiveness factors: how much to shrink fonts by for smaller screens
export const resFactors = {
    tabletLarge: 0.9,
    tablet: 0.8,
    phone: 0.7,
    phoneSmall: 0.5,
}

// Base font sizes in pixels
export const fontSizes = {
    button: {
        large: 40,
        medium: 30,
        small: 20,
    },
    title: {
        large: 80,
        medium: 60,
        small: 40,
    },
    subtitle: {
        large: 40,
        medium: 30,
        small: 20,
    },
    footnote: {
        large: 20,
        medium: 20,
        small: 15,
    }
}

export const colors = {
    black: "#000000",
    lightBlack: "#2e2e2e",
    grey: "#404040",
    mediumGrey: "#585858",
    lightGrey: "#aaaaaa",
    veryLightGrey: "#e0e0e0",
    darkWhite: "#f0f0f0",
    red: "#c80000",
    veryLightRed: "#ffe4e9",
    white: "#ffffff",
    yellow: "#faff64",
    purple: "#8500A5",
}

export const BACKGROUND_COLOR = colors.white;

// Header height in pixels
export const HEADER_HEIGHT = 60;
export const DEFAULT_LINE_COLOR = colors.veryLightGrey;
export const DEFAULT_TEXT_COLOR = colors.grey;

// Maximum page width in pixels
export const MAX_PAGE_WIDTH = 1000;

import React from 'react'

/**
 * Render component that says there's nothing at the specified route.
 */
function InvalidUrl() {

    const style = {
        margin: "auto",
    }

    return (
        <div className="col invalid-url" style={style}>
            <h1>File Not Found</h1>
            <h3>There doesn't seem to be anything here.</h3>
        </div>
    )
}

export default InvalidUrl

import React from 'react'
import logo from '../../images/ingratePear_1024.png'
import useResponseFactor from '../../hooks/useResponseFactor'

function LogoShowcase() {
    const resFac = useResponseFactor();
    const minImgHeight = 300;
    const imgHeight = Math.max(512 * resFac, minImgHeight);
    const titleSize = 140 * resFac;
    const subtitleSize = 40 * resFac;

    const wrapStyle = {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    }

    const imgStyle = {
        height: `${imgHeight}px`,
        width: "auto",
        padding: "40px",
    }

    const titleStyle = {
        fontFamily: "var(--bold-font)",
        fontSize: `${titleSize}px`,
        color: "black",
        marginBottom: "15px",
    }

    const subtitleStyle = {
        fontWeight: "100",
        fontSize: `${subtitleSize}px`,
    }

    return (
        <div className="row to-col-tablet" style={wrapStyle}>
            <img
                src={logo}
                alt="Ingrate pear logo"
                style={imgStyle}
            />
            <div className="col" style={{ width: "auto" }}>
                <h1 style={titleStyle}>Ingrate</h1>
                <h3 style={subtitleStyle}>xr that's got a pear</h3>
            </div>
        </div>
    )
}

export default LogoShowcase

/**
 * Context that provides state/functions for anything auth-related, which in
 * this app means signing in and out. 
 */

import React, { useContext, useState, useEffect } from 'react'
import { auth } from '../services/firebase.js';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    // The user currently signed in.
    const [currentUser, setCurrentUser] = useState();
    // Loading is true until user is set.
    const [loading, setLoading] = useState(true);

    // Set the user accordingly whenever the auth state changes.
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            setLoading(false); // once you've got the user, loading is false
        })
        return unsubscribe;
    }, [])

    /**
     * Sign in with Firebase.
     * @param {*} email User's email.
     * @param {*} password User's password.
     */
    function signIn(email, password) {
        return auth.signInWithEmailAndPassword(email, password);
    }

    /**
     * Sign out with Firebase.
     */
    function signOut() {
        return auth.signOut();
    }

    const value = {
        currentUser,
        signIn,
        signOut,
    }

    // Only render children if loading is false - ie, after setting user in 
    // useEffect/onAuthStateChanged.
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}

import React from 'react'
import Legal from './Legal'

/**
 * Renders a Legal component filled with the privacy policy.
 */
function Privacy() {
    return (
        <div className="col">
            <Legal text={privacyText} />
        </div>
    )
}

export default Privacy

const privacyText = {
    title: "Privacy Policy",
    lastUpdated: "December 17, 2021",
    sections: [
        {
            head: "Introduction",
            pars: [
                'Ingrate, LLC develops and publishes games, entertainment, and other media for the web and mobile devices. In this Privacy Policy “Ingrate”, “we”, “our”, “us”, or other first person pronouns refer to Ingrate, LLC.',
                'This Privacy Policy applies whenever you use our “Service” or “Services”, which means any game we have developed or published, the ingratexr.com website, any other website or domain operated by Ingrate, or any other product, service, or content we provide.',
                "If you do not consent to Ingrate's collection, storage, use, or sharing of information as described in this Privacy Policy, you may not use our Services. If you use our Services you are consenting to have your information handled as described in this Privacy Policy."
            ],
        },
        {
            head: "Information We Collect",
            pars: [
                "When you use our Services you may give us information directly (for example, if you contact us we may see your name and/or email address, or if you decide to enter a personally-identifiable username on a leaderboard).",
                "When you use our Services we collect information about the ways you use and interact with our Services. This information may include data about how long or how often you use the Services, which features you use, how much progress you achieve, whether your device uses iOS or Android, and other usage information. If you access a website or domain that we operate, such as www.ingratexr.com, this information may include your computer's IP address, information about your browser type and version, the pages of the website that you visit, the time and date of your visit, how much time you spend on the website, unique device identifiers, information about another page that may have referred you to the website, and other similar kinds of data.",
            ],
        },
        {
            head: "How We Use the Information We Collect",
            pars: [
                "We use the information we collect to provide, personalize, maintain, improve, and measure the effectiveness of our Services, to maintain our business operations, to communicate with you, to carry out marketing and advertising, to comply with our legal obligations, and otherwise as necessary or convenient for us to legally and ethically carry out our business purposes.",
            ]
        },
        {
            head: "Advertising and Other Third Parties",
            pars: [
                "We use advertising to allow us to offer some Services for free. When you use our Services some of your information may be shared with advertisers to tailor the advertisements you see.",
                "To enable advertisers and/or ad-networks to show you ads, they may collect, or we may collect and share with them, certain types of information, including technical information (like an IP address or a device identifier), information about the game you're playing, or other automatically generated information.",
                `In addition to third party advertisers, Ingrate uses other third party services for other purposes, including but not limited to analytics, web hosting, and technical purposes (for example, technologies that underlie a device's ability to display “Augmented Reality” or “AR”). The third party companies that we use have their own privacy policies which outline what information they collect, how they use it, and where they may share it. These policies are different from Ingrate's. Please familiarize yourself with these privacy policies. Ingrate does not control how these third parties collect, use, store, or transfer information. If you do not consent to have your information treated as described in these third party privacy policies, you may not use Ingrate's Services.`,
            ],
            subSections: [
                {
                    head: "Unity",
                    pars: [
                        "Some of our Services use Unity Ads and/or Unity Analytics, provided by Unity Technologies."
                    ],
                    link: "https://unity3d.com/legal/privacy-policy",
                },
                {
                    head: "Google",
                    pars: [
                        "Some of our Services use Google Analytics and/or Google Play Services, which are provided by Google LLC.",
                    ],
                    link: "https://policies.google.com/privacy",
                },
                {
                    head: "Firebase",
                    pars: [
                        "Some of our Services use Firebase.",
                    ],
                    link: "https://firebase.google.com/support/privacy/",
                },
                {
                    head: "Apple",
                    pars: [
                        "On Apple devices our Services may use technologies provided by Apple.",
                    ],
                    link: "www.apple.com/legal/privacy/en-ww/",
                },
            ]
        },
        {
            head: "Cookies",
            pars: [
                "Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.",
                "Ingrate, or a third party service that Ingrate uses, may use cookies on the ingratexr.com website, or on other websites or domains that Ingrate operates. If you choose to refuse cookies, you may not be able to use some portions of our Services.",
            ],
        },
        {
            head: "Compliance With the Law",
            pars: [
                "Ingrate may share information we collect when necessary for safety, security, or compliance with law. For example, Ingrate may share information in response to lawful requests by public authorities such as law enforcement, national security authorities, or legal processes such as a court order, search warrant, or subpoena.",
            ],
        },
        {
            head: "Sale or Merger",
            pars: [
                "If Ingrate undergoes or evaluates undergoing a business transition such as a merger, acquisition, sale of its assets or an asset, or other change to our business, we may share or transfer some or all of your information to the interested party and/or successor organization in the transition.",
            ],
        },
        {
            head: "Users in Europe and California",
            pars: [
                "To exercise your rights to access, deletion, or other treatment of your information, please contact us at info@ingratexr.com.",
            ],
            subSections: [
                {
                    head: "Europe",
                    pars: [
                        'If you are a user in a country in the European Union or European Economic Area, you have certain rights as laid out in the General Data Protection Regulation, or “GDPR".'
                    ],
                    link: "https://gdpr-info.eu/chapter-3/",
                },
                {
                    head: "California",
                    pars: [
                        'If you are a user in the state of California, you have certain rights as laid out in the California Consumer Privacy Act, or “CCPA”.'
                    ],
                    link: "http://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?lawCode=CIV&division=3.&title=1.81.5.&part=4.&chapter=&article=",
                },
            ]
        },
        {
            head: "Children",
            pars: [
                `Ingrate's Services are not intended for children under the age of 16 (a “Child” or “Children”). Children are not permitted to use our Services. We do not knowingly collect personal information from or about Children.`,
                "If you believe a Child has provided this kind of information to us, or that we have inadvertently collected it, we strongly encourage you to contact us immediately and we will make every effort to promptly remove any such information from our records.",
            ],
        },
        {
            head: "How Long We Keep Information",
            pars: [
                "How long Ingrate retains the information we collect depends on what kind of information it is, why we collected it, and what we use it for. Ingrate will not retain your information longer than necessary for business purposes or legal requirements.",
            ],
        },
        {
            head: "Security of Information",
            pars: [
                "Ingrate has taken appropriate, reasonable steps to protect the security of the information we collect and store. These steps may vary depending on the sensitivity of the type of information being collected or stored. Regardless of the steps Ingrate takes with respect to keeping information secure, no website or internet transmission is perfectly secure. Ingrate cannot and does not guarantee that hacking, data loss, data breach, or any other unauthorized access will never occur.",
            ],
        },
        {
            head: "Changes to Privacy Policy",
            pars: [
                "This Privacy Policy may be changed or updated at any time. You should check it regularly.",
            ],
        },
        {
            head: "Contact",
            pars: [
                "If you have questions or concerns about this Privacy Policy or how Ingrate collects or uses data, please contact us at info@ingratexr.com",
            ],
        },
    ]
}
/* https://github.com/fakiolinho/react-loading */

import React from 'react'
import ReactLoading from 'react-loading'

function Loading() {

    return (
        <div className="col loading">
            <ReactLoading
                type={"spinningBubbles"}
                color={"var(--red)"}
                height={100}
                width={100}
            />
        </div>
    )
}

export default Loading

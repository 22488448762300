import React from 'react'
import PageWrap from '../shared/PageWrap'
import LogoShowcase from './LogoShowcase'
import Btn from '../shared/Btn'
import Bumper from '../shared/Bumper'
import Blurb from '../shared/Blurb'
import { useNavigate } from 'react-router-dom'
import { useContent } from '../../contexts/ContentContext'

function Home() {
    const { text } = useContent();
    const nav = useNavigate();
    return (
        <PageWrap>
            <LogoShowcase />
            <Bumper size={20} />
            <Btn
                text="Show Me"
                onClick={() => nav("/work")}
            />
            <Bumper size={60} />
            {text?.homeBlurb &&
                <Blurb
                    title="About"
                    text={text.homeBlurb}
                />
            }
        </PageWrap>
    )
}

export default Home

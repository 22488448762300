import React, { useContext, useState, useEffect } from 'react'
import { db } from '../services/firebase';

const ContentContext = React.createContext();

export function useContent() {
    return useContext(ContentContext);
}

export function ContentProvider({ children }) {
    const [workSections, setWorkSections] = useState(null);
    const [projectList, setProjectList] = useState(null);
    const [text, setText] = useState(null);

    /**
     * Get all work sections.
     */
    useEffect(() => {
        const subscribeWorkSections = () => {
            return db.collection(`work_sections`)
                .onSnapshot(snapshot => {
                    if (snapshot.docs.length >= 1) {
                        const array = snapshot.docs.map(doc => {
                            const d = doc.data();
                            d.id = doc.id;
                            return d;
                        });
                        array.sort((a, b) => a.order - b.order);
                        setWorkSections(array);
                    } else {
                        setWorkSections(null);
                    }
                }, error => {
                    console.log("error getting work sections: ", error)
                    setWorkSections({ error });
                });
        }

        // Return unsubscribe cleanup function.
        return subscribeWorkSections();
    }, []);

    /**
     * Get all projects.
     */
    useEffect(() => {
        const subscribeProjects = () => {
            return db.collection(`projects`)
                .onSnapshot(snapshot => {
                    if (snapshot.docs.length >= 1) {
                        const array = snapshot.docs.map(doc => {
                            const d = doc.data();
                            d.id = doc.id;
                            return d;
                        });
                        setProjectList(array);
                    } else {
                        setProjectList(null);
                    }
                }, error => {
                    console.log("error getting projects: ", error)
                    setProjectList({ error });
                });
        }

        // Return unsubscribe cleanup function.
        return subscribeProjects();
    }, [])

    /**
     * Get text for Home and Contact pages.
     */
    useEffect(() => {
        const getText = async () => {
            return db.doc(`admin/site`)
                .onSnapshot(doc => {
                    setText(doc.data())
                }, error => {
                    console.log("error getting text: ", error)
                    setText(null);
                });
        }
        getText();
    }, [])

    const updateProject = async (id, project) => {
        try {
            await db.doc(`projects/${id}`)
                .set(project)
                .catch(error => {
                    return { error }
                });
            return { success: true }
        } catch (error) {
            return { error }
        }
    }

    const updateWorkSection = async (id, section) => {
        try {
            await db.doc(`work_sections/${id}`)
                .set(section)
                .catch(error => {
                    return { error }
                });
            return { success: true }
        } catch (error) {
            return { error }
        }
    }

    const updateSiteText = async (textObj) => {
        try {
            await db.doc(`admin/site`)
                .set(textObj, { merge: true })
                .catch(error => {
                    return { error }
                });
            return { success: true }
        } catch (error) {
            return { error }
        }
    }

    /**
     * Create and return a random unique id.
     * @returns {string} A new random Firebase Firestore ID.
     */
    const createId = () => {
        const doc = db.collection('idCreation').doc();
        return doc.id;
    }

    const value = {
        workSections,
        projectList,
        updateProject,
        updateWorkSection,
        createId,
        text,
        updateSiteText,
    }

    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}

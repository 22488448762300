import React, { useState, useEffect } from 'react'
import { useContent } from '../../contexts/ContentContext';
import TextEditor from '../shared/TextEditor';
import Loading from '../shared/Loading';
import Alert from '../shared/Alert';
import Bumper from '../shared/Bumper';
import Btn from '../shared/Btn';

function EditSite() {
    const { text, updateSiteText } = useContent();
    const [homeBlurb, setHomeBlurb] = useState("");
    const [contactBlurb, setContactBlurb] = useState("");
    const [reverter, setReverter] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const revert = () => {
        setReverter(!reverter);
    }

    useEffect(() => {
        setHomeBlurb(text?.homeBlurb || "");
        setContactBlurb(text?.contactBlurb || "");
    }, [text, reverter])

    const saveChanges = async () => {
        setLoading(true);
        setError(null);
        const res = await updateSiteText({
            homeBlurb,
            contactBlurb,
        });
        if (res.error) {
            console.log("error updating: ", res);
            setError({
                headline: "Error",
                body: "Could not update site text.",
                isError: true,
            });
        } else {
            setError({
                headline: "Success",
                body: "Updated, and the peasants rejoiced.",
                isError: false,
            })
        }
        setLoading(false);
    }

    return (
        <div className="col" style={{ maxWidth: "var(--max-nav-width)" }}>
            <Bumper size={60} />
            {error &&
                <Alert
                    headline={error.headline || "Error"}
                    body={error.body || "Undefined error."}
                    isError={error.isError}
                />
            }
            <TextEditor
                value={homeBlurb}
                onChange={(e) => setHomeBlurb(e.target.value)}
                label="Home Page"
                unsavedChanges={homeBlurb !== text?.homeBlurb}
                minRows={10}
                maxRows={15}
            />
            <Bumper size={10} />
            <TextEditor
                value={contactBlurb}
                onChange={(e) => setContactBlurb(e.target.value)}
                label="Contact Page"
                unsavedChanges={contactBlurb !== text?.contactBlurb}
                minRows={10}
                maxRows={15}
            />
            <Bumper size={40} line={true} />
            {!loading &&
                <div className="row">
                    <Btn
                        text="Revert"
                        onClick={revert}
                        disabled={homeBlurb === text?.homeBlurb
                            && contactBlurb === text?.contactBlurb}
                    />
                    <Btn
                        text="Save"
                        onClick={saveChanges}
                        disabled={homeBlurb === text?.homeBlurb
                            && contactBlurb === text?.contactBlurb}
                    />
                </div>
            }
            {loading && <Loading />}
        </div>
    )
}

export default EditSite

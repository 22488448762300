import React, { useState, useEffect } from 'react'
import PageWrap from '../shared/PageWrap'
import { useLocation } from 'react-router-dom'
import WorkMenu from './WorkMenu'
import { useContent } from '../../contexts/ContentContext'
import Section from './Section'
import { RenderRoutes } from '../../routing/Routes'
import Loading from '../shared/Loading'
import Alert from '../shared/Alert'

function Work() {
    const [error, setError] = useState(null);
    const [menuConfig, setMenuConfig] = useState(null);
    const nullRouteConfig = [{
        path: "/*",
        key: "/*",
        element: Loading,
    }]
    const [routeConfig, setRouteConfig] = useState(null);
    const { workSections } = useContent();

    useEffect(() => {
        if (!workSections) {
            setMenuConfig(null);
            setRouteConfig(null);
            return;
        } else if (workSections.error) {
            setError({
                headline: "Error",
                body: "Could not load page.\nPlease try again later.",
                isError: true,
            })
            setMenuConfig(null);
            setRouteConfig(null);
            return;
        }

        setError(null);

        // Set menu config mapping directly from work sections.
        setMenuConfig(workSections.map(s => {
            const to = `/work/${s.path}`;
            return ({
                text: s.title,
                to: to.replaceAll("//", "/"),
            })
        }))

        // Don't set route config directly from work sections. Create a 
        // redundant element for the "/" path. Use whatever the first/leftmost
        // menu item is.
        const routes = [...workSections];
        routes.forEach(r => {
            const el = () => {
                return (
                    <Section
                        blurb={r.blurb}
                        projects={r.projects || []}
                    />
                )
            }
            r.element = el;
        })
        const rootPath = { ...routes[0] };
        rootPath.path = "/";
        routes.push(rootPath);
        setRouteConfig(routes);
    }, [workSections]);

    const location = useLocation();

    return (
        <PageWrap title={"Work"}>
            {menuConfig
                ? <WorkMenu
                    links={menuConfig}
                    location={
                        location.pathname === "/work"
                            || location.pathname === "/work/"
                            ? menuConfig[0].to
                            : location.pathname
                    }
                />
                : <Loading />
            }
            {error &&
                <Alert
                    headline={error.headline}
                    body={error.body}
                    isError={true}
                />
            }
            {menuConfig &&
                <RenderRoutes routes={routeConfig || nullRouteConfig} />
            }
        </PageWrap >
    )
}

export default Work

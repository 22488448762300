import Nav from './components/hdrFtr/Nav';
import Footer from './components/hdrFtr/Footer';
import { BrowserRouter } from 'react-router-dom';
import { appRoutes } from './routing/appRouteConfig';
import { RenderRoutes } from './routing/Routes';
import ScrollToTop from './components/shared/ScrollToTop';
import { ContentProvider } from './contexts/ContentContext';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="col outer-app-wrap">
        <ContentProvider>
          <Nav />
          <div className="col inner-app-wrap">
            <RenderRoutes routes={appRoutes} />
          </div>
          <Footer />
        </ContentProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;

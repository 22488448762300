import React from 'react'

/**
 * Renders an alert to the user, either all red if an error or all green if not.
 * Alert consists of a headline, and/or body, and/or series of links, and/or
 * any number of children.
 * @param children Any children (eg buttons) that should appear within the alert
 * box, below the headline, body, and links.
 * @param headline Bold text at the top of the box.
 * @param body Paragraph below the headline.
 * @param isError If true, everything will be red. If false, green.
 * @param bg If true, alert box will have either red or green background. If
 * false, background will be white. (Default is true.)
 * @param links Optional array of links that will appear as bold text.
 */
function Alert({
    children,
    headline,
    body,
    isError,
    bg = true,
    links = []
}) {
    // Pick text/outline color based on whether it's an error.
    const color = isError ? "var(--red)" : "var(--green)";
    const divStyle = {
        border: `2px solid ${color}`,
        borderRadius: "var(--border-radius)",
        backgroundColor: !bg
            ? "var(--white)"
            : isError ? "var(--light-red)" : "var(--light-green)",
        padding: "20px",
        margin: "20px",
        maxWidth: "var(--max-nav-width)",
    }

    // Create a bolded/headline link to go inside the Alert
    /**
     * Returns a bolded/headline link.
     * @param text The text the link will display.
     * @param link The url to link to.
     * @param key The unique key (usually index of link in list).
     */
    const linkify = (text, link, key) => {
        return (
            <h5
                key={key}
                className="headline"
                style={{
                    marginTop: "15px",
                    color,
                }}
                onClick={() => {
                    window.open(link,
                        '_blank',
                        'noopener,noreferrer');
                }}
            >
                {text}
            </h5>
        )
    }

    return (
        <div className="col" style={divStyle}>
            {headline &&
                <h1
                    className="headline"
                    style={{ color }}
                >
                    {headline}
                </h1>
            }
            {body &&
                <p style={{
                    margin: "10px 0px",
                    width: "100%",
                    color,
                    whiteSpace: "pre-line"
                }}
                >
                    {body}
                </p>
            }
            {
                links.map((l, i) => {
                    return (
                        linkify(l.text, l.link, i)
                    )
                })
            }
            {children}
        </div >
    )
}

export default Alert

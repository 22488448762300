import React from 'react'

function LabeledComponent({ children, label = "" }) {

    const splitLabelByNewLine = (initial) => {
        const split = initial.split(/\r\n|\r|\n|\\n/)
        return split.filter(s => s);
    }

    const labelStyle = {
        marginBottom: "5px",
        width: "100%",
        textAlign: "left",
        whiteSpace: "pre-line",
    }

    return (
        <>
            {label && splitLabelByNewLine(label).map((l, i) => {
                return <h5 key={i} style={labelStyle}>{l}</h5>
            })}
            {children}
        </>
    )
}

export default LabeledComponent

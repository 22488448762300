import React from 'react'

function PageWrap({ children, title }) {

    const titleStyle = {
        fontFamily: "var(--bold-font)",
        fontSize: "80px",
        margin: "30px 0px",
    }

    const pageStyle = {
        //minHeight: "var(--min-page-height)",
        padding: "20px 0px",
        width: "100%",


        //border: "1px solid var(--dark-white)",
    }

    return (
        <div
            className="col"
            style={pageStyle}
        >
            {title && <h1 style={titleStyle}>{title}</h1>}
            {children}
        </div>
    )
}

export default PageWrap

import React from 'react'
/**
 * Renders a legal text object as a page with a title and series of sections.
 * @param text Object with the fields:
 * - title (string)
 * - lastUpdated (date)
 * - sections (list of section objects)
 * @returns 
 */
function Legal({ text }) {

    const wrapStyle = {
        width: "100%",
        maxWidth: "600px",
        alignItems: "flex-start",
    }

    const headerStyle = {
        width: "100%",
        margin: "10px 0px",
    }

    const paraStyle = {
        width: "100%",
        textAlign: "left",
        marginBottom: "5px",
        fontSize: "14px",
    }

    const sectionStyle = {
        textAlign: "left",
        margin: "20px 0px",
    }

    const linkStyle = {
        fontWeight: "bolder",
        cursor: "pointer",
        fontSize: "14px",
        marginBottom: "5px",
    }

    /**
     * Formats a section object.
     * @param {*} sec Object with fields:
     * - head (string to render as headline)
     * - pars (list of strings to render as paragraphs)
     * - subSections (list of subsections consisting of a head, a list of pars,
     * and possibly a link, which would have a link and a linkText element)
     * - link (url to link to)
     * - linkText (text to show up for link of url)
     * @param {*} index Index of the section (to use as key)
     */
    const formatSection = (sec, index) => {
        return (
            <div key={index} style={sectionStyle}>
                {sec.head &&
                    <h3 style={headerStyle}>
                        {sec.head}
                    </h3>
                }
                {sec.pars?.map((p, i) => {
                    return (
                        <p key={i} style={paraStyle}>
                            {p}
                        </p>
                    )
                })}
                {sec.subSections?.map((s, i) => {
                    return (
                        <div className="col" key={i} style={wrapStyle}>
                            {s.head &&
                                <h5 style={headerStyle}>
                                    {s.head}
                                </h5>
                            }
                            {s.pars?.map((p, j) => {
                                return (
                                    <p key={j} style={paraStyle}>
                                        {p}
                                    </p>
                                )
                            })}
                            {s.link &&
                                <a
                                    href={s.link}
                                    style={linkStyle}
                                    target={"_blank"}
                                    rel={"noreferrer noopener"}
                                >
                                    {s.linkText ? s.linkText : s.link}
                                </a>
                            }
                        </div>
                    )
                })}
                {sec.link &&
                    <a
                        href={sec.link}
                        style={linkStyle}
                        target={"_blank"}
                        rel={"noreferrer noopener"}
                    >
                        {sec.linkText ? sec.linkText : sec.link}
                    </a>
                }
            </div>
        )
    }

    return (
        <div className="col">
            <h1 style={{ marginBottom: "20px" }}>{text.title || ""}</h1>
            {text.lastUpdated && <h3>Last Updated: {text.lastUpdated}</h3>}
            <div style={wrapStyle}>
                {text.sections.map((s, i) => formatSection(s, i))}
            </div>
        </div>
    )
}

export default Legal

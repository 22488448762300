import React from 'react'
import { Link } from 'react-router-dom'

function WorkMenu({ links, location }) {
    const style = {
        borderBottom: "1px solid var(--very-light-grey)",
        //borderTop: "1px solid var(--very-light-grey)",
        height: "auto",
        minHeight: "60px",
        width: "100%",
        padding: "10px",
    }

    const linkStyle = (curr) => {
        return ({
            fontFamily: curr ? "var(--bold-font)" : "",
            fontSize: "20px",
            textTransform: curr ? "uppercase" : "none",
            fontWeight: curr ? "500" : "100",
            textDecoration: "none",
        })
    }

    return (
        <div className="row to-col-phone" style={style}>
            {links.map((l, i) => {
                return (
                    <Link
                        key={i}
                        to={l.to}
                        style={linkStyle(location.includes(l.to))}
                        // Using flex column means the choices won't move when
                        // the selection changes.
                        className="col"
                    >
                        {l.text}
                    </Link>
                )
            })}
        </div>
    )
}

export default WorkMenu

import React from 'react'

function UnsavedChangesWrap({ children, unsavedChanges }) {

    const style = {
        border: `${unsavedChanges ? "1px solid var(--red)" : ""}`,
        borderRadius: "var(--border-radius)",
        width: `${unsavedChanges ? "110%" : "100%"}`, //"110%",
        padding: `${unsavedChanges ? "5%" : ""}`,
    }

    const textStyle = {
        color: "var(--red)",
        marginBottom: "10px",
        width: "100%",
        textAlign: "left",
        fontWeight: "400",
    }

    return (
        <div style={style}>
            {unsavedChanges &&
                <h5 style={textStyle}>
                    Unsaved Changes
                </h5>}
            {children}
        </div>
    )
}

export default UnsavedChangesWrap

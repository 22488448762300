import React from 'react'
import UnsavedChangesWrap from './UnsavedChangesWrap'
import LabeledComponent from './LabeledComponent'
import { TextareaAutosize } from '@material-ui/core'

function TextEditor({
    value,
    onChange,
    unsavedChanges = false,
    label,
    placeholder,
    error = null,
    minRows = 1,
    maxRows = 1,
    password = false,
}) {

    const errorStyle = {
        //width: "100%",
        border: "1px solid var(--red)",
        borderRadius: "var(--border-radius)",
        backgroundColor: "var(--very-light-red)",
        padding: "10px",
        margin: "10px",
    }

    const errorElement = () => {
        if (!error) return null;
        return (
            <div style={errorStyle}>
                <h3 style={{ margin: "auto", color: "var(--red)", width: "100%", textAlign: "center" }}>{error}</h3>
            </div>
        )
    }

    const style = {
        width: "100%",
        marginTop: "5px",
        minHeight: "35px",
        border: "1px solid var(--light-grey)",
        borderRadius: "var(--text-border-radius)",
        fontSize: "16px",
        padding: "5px",
    }

    return (
        <UnsavedChangesWrap
            unsavedChanges={unsavedChanges}
        >
            {errorElement()}
            <LabeledComponent label={label}>
                {password
                    ? <input
                        type="password"
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        style={style}
                    />
                    : <TextareaAutosize
                        minRows={minRows}
                        maxRows={maxRows}
                        aria-label="maximum height"
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        style={style}
                    />
                }
            </LabeledComponent>
        </UnsavedChangesWrap>
    )
}

export default TextEditor
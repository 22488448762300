import React from 'react'
import { Link } from 'react-router-dom'

function Nav() {

    const links = [
        {
            text: "Home",
            to: "/",
        },
        {
            text: "Work",
            to: "/work",
        },
        {
            text: "Contact",
            to: "/contact",
        },
    ]

    /**
     * Style the whole nav area - the strip that runs all the way across the top
     * of the app and is fixed in place. The point is that this div's background
     * is a solid color so as the page scrolls behind it, it disappears behind
     * an invisible line that goes all the way across the top of the page and 
     * not just across the middle section where there are links.
     */
    const wrapStyle = {
        height: "var(--header-height)",
        backgroundColor: "white",
        color: "var(--very-light-grey)",
        width: "100%",
    }

    /**
     * Style that wraps the links themselves, limiting the width.
     */
    const menuStyle = {
        height: "100%",
        width: "100%",
        maxWidth: "var(--max-nav-width)",
    }

    return (
        <div className="row" style={wrapStyle}>
            <div className="row" style={menuStyle}>
                {links.map((l, i) => {
                    return (
                        <Link
                            key={i}
                            to={l.to}
                            className="nav-link"
                        >
                            {l.text}
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}

export default Nav

// Custom hook for getting window size

// https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs

import { useState, useEffect } from 'react';

// Returns object that can be destructured: { width, height }
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    const aspectRatio = width * 1.0 / height;
    return {
        width,
        height,
        aspectRatio
    };
}

export default function useWindowSize() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Returns object that can be destructured: { width, height }
    return windowDimensions;
}

import React from 'react'
//import { fontSizes } from './magicConstants'
import useResponseFactor from '../../hooks/useResponseFactor'
import useWindowSize from '../../hooks/useWindowSize';
import { breakpoints } from './magicConstants';

function Blurb({ title, text }) {
    const { width } = useWindowSize();
    const isPhone = width < breakpoints.phone;
    const resFac = useResponseFactor();
    const titleSize = 40 * Math.min(resFac * 1.25, 1);
    const textSize = 30 * Math.min(resFac * 1.25, 1);

    const wrapStyle = {
        width: "100%",
        maxWidth: "calc(0.75 * var(--max-page-width)",
    }

    const titleStyle = {
        fontFamily: "var(--bold-font)",
        width: "100%",
        textAlign: isPhone ? "center" : "left",
        fontSize: `${titleSize}px`,
        marginBottom: "15px",
    }
    const textStyle = {
        width: "100%",
        fontSize: `${textSize}px`,
        fontWeight: "200",
        textAlign: isPhone ? "center" : "left",
    }

    return (
        <div className="col" style={wrapStyle}>
            {title && <h3 style={titleStyle}>{title}</h3>}
            <p style={textStyle}>{text}</p>
        </div>
    )
}

export default Blurb

import React, { useState, useEffect } from 'react';
import { useContent } from '../../contexts/ContentContext';
import Btn from '../shared/Btn';
import Bumper from '../shared/Bumper';
import LabeledComponent from '../shared/LabeledComponent';
import TextEditor from '../shared/TextEditor';
import UnsavedChangesWrap from '../shared/UnsavedChangesWrap';
import Loading from '../shared/Loading';
import Alert from '../shared/Alert';
import Project from '../work/Project'

function EditProjects() {
    const { projectList, updateProject, createId } = useContent();
    const [projectId, setProjectId] = useState("");
    const [project, setProject] = useState(null);
    const [title, setTitle] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [youTube, setYouTube] = useState("");
    const [client, setClient] = useState("");
    const [synopsis, setSynopsis] = useState("");
    const [platform, setPlatform] = useState("");
    const [builtWith, setBuiltWith] = useState("");
    const [links, setLinks] = useState([]);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Dummy variable to toggle whenever I want the useEffect that has it as a
    // dependency to run. (So that I can trigger that useEffect code when I push
    // a button, without putting the logic outside of the effect, which would
    // cause the effect to run on every render.)
    const [reverter, setReverter] = useState(true);

    const SPACE_BETWEEN_TEXT_FIELD = 10;

    const revert = () => {
        setReverter(!reverter);
    }

    useEffect(() => {
        setProject(projectList.find(p => p.id === projectId) || null)
    }, [projectId, projectList])

    useEffect(() => {
        // It doesn't work to just create a copy of the arry (ie to set links
        // as [...project.links]) because that array just points to the same
        // objects, so mutating the objects in that array mutates the objects
        // in the project's links array. Create a deep copy instead.
        const linksCopy = () => {
            if (!project?.links) {
                return [];
            }
            return project.links.map(l => { return ({ ...l }) })
        }
        setUnsavedChanges(false);
        setTitle(project?.title || "");
        setImgUrl(project?.imgUrl || "");
        setYouTube(project?.youTube || "");
        setClient(project?.client || "");
        setSynopsis(project?.synopsis || "");
        setPlatform(project?.platform || "");
        setBuiltWith(project?.builtWith || "");
        setLinks(linksCopy());
    }, [project, reverter])

    const setLinkField = (index, field, value) => {
        setUnsavedChanges(true);
        const newLinks = [...links];
        newLinks[index][field] = value;
        setLinks(newLinks);
    }

    const newLink = () => {
        setLinks((prev) => [...prev, { text: "", url: "" }])
    }

    const setText = (func, val) => {
        setUnsavedChanges(true);
        func(val);
    }

    /**
     * Create and return a Project object that only contains keys/values for
     * values that are not falsey (ie ignores empty fields entirely rather than
     * saving a key with an empty string).
     * @returns 
     */
    const updatedProject = () => {
        const _links = links.map(l => {
            if (l.url) {
                return {
                    text: l.text || l.url,
                    url: l.url
                }
            } else {
                return null;
            }
        })
        return Object.assign({},
            title && { title },
            imgUrl && { imgUrl },
            youTube && { youTube },
            client && { client },
            synopsis && { synopsis },
            platform && { platform },
            builtWith && { builtWith },
            _links.length > 0 && { links: _links.filter(l => l !== null) },
        )
    }

    const saveProject = async () => {
        setLoading(true);
        setError(null);
        const res = await updateProject(projectId, updatedProject());
        if (res.error) {
            console.log("error saving project: ", res);
            setError({
                headline: "Error",
                body: "Could not update project.",
                isError: true,
            });
        }
        setLoading(false);
    }

    const linksEditor = () => {
        return (
            <LabeledComponent label="Links">
                <div
                    className="col"
                    style={{
                        paddingLeft: "20px",
                        borderLeft: "1px solid var(--very-light-grey)",
                        marginTop: "10px",
                    }}
                >
                    {links.map((l, i) => {
                        return (
                            <div className="col" key={i}>
                                <TextEditor
                                    value={links[i].text}
                                    onChange={(e) =>
                                        setLinkField(i, "text", e.target.value)}
                                    label="Text"
                                />
                                <TextEditor
                                    value={links[i].url}
                                    onChange={(e) =>
                                        setLinkField(i, "url", e.target.value)}
                                    label="Url"
                                />
                                <Bumper
                                    size={SPACE_BETWEEN_TEXT_FIELD * 4}
                                    line={true}
                                />
                            </div>
                        )
                    })}
                    <button
                        onClick={newLink}
                    >
                        New
                    </button>
                </div>
            </LabeledComponent>
        )
    }

    return (
        <div className="col">
            <form>
                <select
                    id="projects"
                    name="projects"
                    onChange={(e) => setProjectId(e.target.value)}
                    style={{
                        marginTop: "60px"
                    }}
                >
                    <option value="" key={-1}>Select Project...</option>
                    {projectList.sort((a, b) => a.title > b.title ? 1 : -1).map((p, i) => {
                        return (
                            <option value={p.id} key={i}>{p.title}</option>
                        )
                    })}
                </select>
            </form>
            {error &&
                <Alert
                    headline={error.headline || "Error"}
                    body={error.body || "Undefined error."}
                    isError={error.isError}
                />
            }
            {!projectId &&
                <Btn
                    text="New Project"
                    onClick={() => setProjectId(createId())}
                />
            }
            {projectId &&
                <>
                    <Project project={updatedProject()} />
                    <Bumper size={120} line={true} />
                </>
            }
            {projectId &&
                <div
                    className="col"
                    style={{
                        maxWidth: "var(--max-nav-width)"
                    }}
                >
                    <UnsavedChangesWrap unsavedChanges={unsavedChanges}>
                        <TextEditor
                            value={title}
                            onChange={(e) =>
                                setText(setTitle, e.target.value)
                            }
                            label="Title"
                        />
                        <Bumper size={SPACE_BETWEEN_TEXT_FIELD} />
                        <TextEditor
                            value={imgUrl}
                            onChange={(e) =>
                                setText(setImgUrl, e.target.value)
                            }
                            label="Image Url"
                            minRows={5}
                            maxRows={5}
                        />
                        <Bumper size={SPACE_BETWEEN_TEXT_FIELD} />
                        <TextEditor
                            value={youTube}
                            onChange={(e) =>
                                setText(setYouTube, e.target.value)
                            }
                            label="YouTube ID (NOT LINK)"
                        />
                        <Bumper size={SPACE_BETWEEN_TEXT_FIELD} />
                        <TextEditor
                            value={client}
                            onChange={(e) =>
                                setText(setClient, e.target.value)
                            }
                            label="Client"
                        />
                        <Bumper size={SPACE_BETWEEN_TEXT_FIELD} />
                        <TextEditor
                            value={synopsis}
                            onChange={(e) =>
                                setText(setSynopsis, e.target.value)
                            }
                            label="Synopsis"
                            minRows={10}
                            maxRows={15}
                        />
                        <Bumper size={SPACE_BETWEEN_TEXT_FIELD} />
                        <TextEditor
                            value={platform}
                            onChange={(e) =>
                                setText(setPlatform, e.target.value)
                            }
                            label="Platform"
                        />
                        <Bumper size={SPACE_BETWEEN_TEXT_FIELD} />
                        <TextEditor
                            value={builtWith}
                            onChange={(e) =>
                                setText(setBuiltWith, e.target.value)
                            }
                            label="Built With"
                        />
                        <Bumper size={SPACE_BETWEEN_TEXT_FIELD} />
                        {linksEditor()}
                    </UnsavedChangesWrap>
                    <Bumper size={40} />
                    {loading
                        ? <Loading />
                        : <div className="row">
                            <Btn
                                onClick={revert}
                                text="Revert"
                                disabled={!unsavedChanges}
                            />
                            <Btn
                                onClick={saveProject}
                                disabled={!unsavedChanges}
                                text="Save"
                            />
                        </div>
                    }

                </div>
            }
        </div>
    )
}

export default EditProjects

import React from 'react'
import Bumper from '../shared/Bumper'
import useWindowSize from '../../hooks/useWindowSize'
import { breakpoints } from '../shared/magicConstants';
import YouTubeEmbed from './YouTubeEmbed';

function Project({ project }) {
    const {
        title,
        imgUrl,
        synopsis,
        links,
        platform,
        builtWith,
        client,
        youTube,
    } = project || {};

    const { width } = useWindowSize();
    const isPhone = width < breakpoints.phone;
    const imgStyle = {
        width: isPhone ? "100%" : "90%",
        height: "auto",
        marginRight: isPhone ? "0px" : "10%",
        marginBottom: isPhone ? "20px" : "0px",
    }

    const leftStyle = {
        maxWidth: isPhone ? "100%" : "calc(0.4 * var(--max-page-width)",
        justifyContent: "flex-start",
    }

    const titleify = (t) => {
        const style = {
            fontFamily: "var(--bold-font)",
            fontSize: "30px",
            textTransform: "uppercase",
            width: "100%",
        }
        return <h3 style={style}>{t}</h3>
    }

    const pair = (label, content) => {
        const pairStyle = {
            marginTop: "20px",
        }
        const labelStyle = {
            fontWeight: "100",
            textTransform: "uppercase",
            fontStyle: "italic",
            fontSize: "18px",
            width: "100%",
        }
        const textStyle = {
            fontWeight: "200",
            fontSize: "18px",
            width: "100%",
            whiteSpace: "pre-line",
            textDecoration: "none",
        }
        const txt = Array.isArray(content)
            ? content.map((c, i) => {
                return (
                    <a
                        key={i}
                        href={c.url}
                        style={textStyle}
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                    >
                        {c.text}
                    </a>
                )
            })
            : <p style={textStyle}>{content}</p>
        return (
            <div className="col" style={pairStyle}>
                <h5 style={labelStyle}>{label}</h5>
                {txt}
            </div>
        )
    }

    return (
        <>
            <Bumper size={120} line={true} />
            <div
                className="row to-col-phone"
                style={{
                    alignItems: "flex-start",
                }}
            >
                <div
                    className="col"
                    style={leftStyle}
                >
                    {youTube
                        ? <YouTubeEmbed embedId={youTube} title={title} />
                        : <img
                            src={imgUrl}
                            alt={title}
                            style={imgStyle}
                        />
                    }
                </div>
                <div className="col">
                    {title && titleify(title)}
                    {synopsis && pair("Synopsis", synopsis)}
                    {client && pair("Client", client)}
                    {links && links.length > 0 && pair("Links", links)}
                    {platform && pair("Platform", platform)}
                    {builtWith && pair("Built With", builtWith)}
                </div>
            </div>
        </>
    )
}

export default Project

import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {

    const links = [
        {
            text: "Privacy Policy",
            to: "/privacy",
        },
        // {
        //     text: "Terms of Service",
        //     to: "/terms",
        // },
    ];

    const style = {
        height: "var(--footer-height)",
        marginTop: "50px",
        color: "var(--very-light-grey)",
        backgroundColor: "white",
        justifyContent: "center",
    }

    return (
        <div
            className="col"
            style={style}
        >
            <p className="nav-link footer-link">
                info@ingratexr.com
            </p>
            {links.map((l, i) => {
                return (
                    <Link
                        key={i}
                        to={l.to}
                        className="nav-link footer-link"
                    >
                        {l.text}
                    </Link>
                )
            })}
            <p className="nav-link footer-link">
                Copyright &copy; 2021 Ingrate, LLC
            </p>
        </div>
    )
}

export default Footer

import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import PageWrap from '../shared/PageWrap'
import TextEditor from '../shared/TextEditor'
import Alert from '../shared/Alert'
import Btn from '../shared/Btn'
import Loading from '../shared/Loading'

function SignIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { signIn } = useAuth();

    const wrapStyle = {
        width: "100%",
        maxWidth: "var(--max-nav-width)",
    }

    const handleTextInput = (event, func) => {
        func(event.target.value);
    }

    /**
     * Handle form submissions from invisible submission button.
     */
    const formSubmit = async (event) => {
        event.preventDefault();
        handleSubmit();
    }

    /**
     * Submit email and password. Catch/handle errors. Worry about loading.
     */
    const handleSubmit = async () => {
        if (disableSubmit()) {
            return;
        }
        setError(null);
        setLoading(true);
        try {
            const res = await signIn(email, password);
            if (res.error) {
                setError({
                    headline: "Error",
                    body: error.error.message,
                });
                setLoading(false);
            }
        } catch (err) {
            console.log("error: ", err)
            setError({
                headline: "Error",
                body: "Could not sign in.",
            })
            setLoading(false);
        }
    }

    const disableSubmit = () => {
        return !email || !password || loading;
    }

    return (
        <PageWrap title="Admin">
            {error &&
                <Alert
                    headline={error.headline}
                    body={error.body}
                    isError={true}
                />
            }
            <form
                style={wrapStyle}
                onSubmit={formSubmit}
                className="col"
            >
                <TextEditor
                    value={email}
                    onChange={(e) => handleTextInput(e, setEmail)}
                    label={"Email"}
                    placeholder={"emusk6969@hotmail.com"}
                />
                <TextEditor
                    value={password}
                    onChange={(e) => handleTextInput(e, setPassword)}
                    label={"Password"}
                    placeholder={"b00b$"}
                    password={true}
                />
                <input
                    type="submit"
                    style={{ display: "none" }}

                />
                {loading
                    ? <Loading />
                    : <Btn
                        text={"Sign In"}
                        disabled={disableSubmit()}
                        onClick={handleSubmit}
                    />
                }
            </form>
        </PageWrap>
    )
}

export default SignIn

import React, { useState } from 'react'
import Btn from '../shared/Btn'
import PageWrap from '../shared/PageWrap'
import { useAuth } from '../../contexts/AuthContext'
import EditSite from './EditSite'
import EditWork from './EditWork'
import EditProjects from './EditProjects'
import Bumper from '../shared/Bumper'

function Admin() {
    const { signOut } = useAuth();
    const choices = [
        {
            text: "Site",
            element: <EditSite />
        },
        {
            text: "Work",
            element: <EditWork />
        },
        {
            text: "Projects",
            element: <EditProjects />
        }
    ]
    const [selected, setSelected] = useState(choices[0]);

    const menuStyle = {
        borderBottom: "1px solid var(--very-light-grey)",
        height: "auto",
        minHeight: "60px",
        width: "100%",
        padding: "10px",
    }

    const linkStyle = (curr) => {
        return ({
            fontFamily: curr ? "var(--bold-font)" : "",
            fontSize: "20px",
            textTransform: curr ? "uppercase" : "none",
            fontWeight: curr ? "500" : "100",
            textDecoration: "none",
            cursor: curr ? "" : "pointer",
        })
    }

    return (
        <PageWrap title={"Admin"}>
            <div
                className="row to-col-phone"
                style={menuStyle}
            >
                {choices.map((c, i) => {
                    return (
                        <p
                            key={i}
                            style={linkStyle(c.text === selected.text)}
                            // Using flex column means the choices won't move when
                            // the selection changes.
                            className="col"
                            onClick={() => setSelected(c)}
                        >
                            {c.text}
                        </p>
                    )
                })}
            </div>
            {selected.element}
            <Bumper size={80} line={true} />
            <Btn
                text="Sign Out"
                onClick={signOut}
            />
        </PageWrap >
    )
}

export default Admin

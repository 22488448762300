import Home from "../components/home/Home"
import Work from "../components/work/Work"
import Contact from "../components/contact/Contact"
import AdminWrap from "../components/admin/AdminWrap"
import { AuthProvider } from "../contexts/AuthContext"
import Privacy from '../components/legal/Privacy'

/**
 * Wrap the AdminWrap component in the Auth context it needs here so that the
 * AdminWrap can just protect the Admin route.
 * The alternative would be to render the AdminWrap component here, have it
 * contain an AuthProvider context, and then have some child component access
 * the AuthProvider. This structure has one less layer and avoids something
 * hacky like hard coding an AdminWrap component into the AuthProvider. But it
 * does look weird.
 */
const wrappedAdmin = () => {
    return (
        <AuthProvider>
            <AdminWrap />
        </AuthProvider>
    )
}

/**
 * Routes that have subroutes need to end with the "/*" wildcard. This replaces
 * exact=true from react-router-dom v5.x => v6.
 */

export const appRoutes = [
    {
        path: "/",
        key: "home",
        element: Home,
    },
    {
        path: "/work/*",
        key: "work",
        element: Work,
    },
    {
        path: "/contact",
        key: "contact",
        element: Contact,
    },
    {
        path: "/admin/*",
        key: "admin",
        element: wrappedAdmin,
    },
    {
        path: "/privacy",
        key: "privacy",
        element: Privacy,
    },
]

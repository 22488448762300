import React from 'react'
import Blurb from '../shared/Blurb'
import { useContent } from '../../contexts/ContentContext'
import Project from './Project';
import Bumper from '../shared/Bumper';

function Section({ blurb, projects = [] }) {
    const { projectList } = useContent();
    const style = {
        marginTop: "30px",
    }
    return (
        <div className="col" style={style}>
            <Bumper size={40} />
            <Blurb text={blurb} />
            {projects.map((p, i) => {
                const proj = projectList?.find(q => q.id === p) || null;
                return (
                    <Project key={i} project={proj} />
                )
            })}
        </div>
    )
}

export default Section
